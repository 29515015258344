import React from 'react'
import { Layout } from '../layouts/Layout'
import useFetchOutages from '../services/useFetchOutages'

export const apiUrl =
  'http://web-cms.superloop.com/api/collections/partials/entries/802fdde8-fdac-47e0-b049-0402bbb3610d'

const Outages = () => {
  const content = useFetchOutages(apiUrl)

  return (
    <Layout>
      <div className="outage-banner">
        <div className="p-4">
          <h1 className="banner-title text-white">
            Outages and Network Status
          </h1>
        </div>
      </div>
      <section className="outage text-dark">
        <div className="container">
          <div className="col">
            <div className="container mt-3">
              <p>
                The best way to receive up-to-date notifications on any planned
                or unplanned outages is via our mobile app.
              </p>
              <p className="mt-3">
                The status of our network and that of our wholesalers is shown
                below.
              </p>
            </div>

            <div className="container mt-5">
              {content.map((block, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              ))}
            </div>

            <div className="container mt-5 mb-5">
              <p className="font-weight-bold">Our Wholesalers</p>
              <p className="mt-3">
                For information on outages affecting the nbn®, Opticomm, or our
                mobile customers, please visit the links below:
              </p>
              <ul className="mt-3">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.nbnco.com.au/support/network-status"
                  >
                    nbn® outages
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://online.telco.opticomm.com.au/network"
                  >
                    Opticomm outages
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.telstra.com.au/outages"
                  >
                    Mobile network outages
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Outages
